import React from 'react';
import { Redirect } from 'react-router-dom';
import preLoginStore from './login/preLoginStore';

// Class to check if correlationId is present, otherwise redirect to login

const isFirstTimeLogin = localStorage.getItem('isFirstTimeLogin');
class OtpProtectedRoute extends React.Component {
    render() {
        const Component = this.props.component;
        const isAlreadyAuthenticated = localStorage.getItem('access_token');
        const isCorrelationId = localStorage.getItem('correlationId');

        if (isAlreadyAuthenticated && isFirstTimeLogin === 'false') {
            return <Redirect to={{ pathname: '/dashboard' }} />;
        } else {
            return isCorrelationId ? <Component /> : <Redirect to={{ pathname: '/' }} />;
        }
    }
}

// Class to check if user is logged in already and prevent duplicate login
class AllowLoginIfTokenPresent extends React.Component {
    render() {
        const Component = this.props.component;
        const isAlreadyAuthenticated = localStorage.getItem('access_token');

        return isAlreadyAuthenticated && isFirstTimeLogin === 'false' ? (
            <Redirect to={{ pathname: '/dashboard' }} />
        ) : (
            <Component />
        );
    }
}
class ChangePasswordIfFirstTimeLogin extends React.Component {
    render() {
        const Component = this.props.component;
        const isAlreadyAuthenticated = localStorage.getItem('access_token');
        const status = localStorage.getItem('status');

        return isAlreadyAuthenticated && status === 410 ? (
            <Redirect to={{ pathname: '/changePassword' }} />
        ) : (
            <Component />
        );
    }
}

export { OtpProtectedRoute, AllowLoginIfTokenPresent, ChangePasswordIfFirstTimeLogin };
