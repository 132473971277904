import { observable, runInAction } from 'mobx';
import { BASE_URL, BASIC_AUTH_USERNAME, BASIC_AUTH_PASSWORD } from './../../../configs';
import { notify } from 'react-notify-toast';
import history from '../../../helpers/routeUtils';
import axios from 'axios';
import { getCubaREST } from '@cuba-platform/react-core';

export default observable({
    isCorrelationIdLoading: false,
    isGetTokenLoading: false,
    isGetUserInfoLoading: false,
    isPostForgotPasswordLoading: false,
    isPostChangePasswordLoading: false,

    async getCorrelationId(username, password) {
        try {
            this.isCorrelationIdLoading = true;
            const correlationIdResponse = await axios.post(
                `${BASE_URL}/oauth/send-token`,
                {
                    username: username,
                    password: password,
                    scope: 'trader',
                },
                {
                    auth: {
                        username: BASIC_AUTH_USERNAME,
                        password: BASIC_AUTH_PASSWORD,
                    },
                }
            );
            if (correlationIdResponse.data.access_token) {
                localStorage.setItem('expiryDate', correlationIdResponse.data.expiry_date);
                localStorage.setItem('isFirstTimeLogin', false);
                localStorage.setItem('access_token', correlationIdResponse.data.access_token);
                await this.getUserInformation();
                getCubaREST().restApiToken = correlationIdResponse.data.access_token;

                notify.show('Login Success', 'success', 2500);
                history.push('/dashboard');
            } else {
                localStorage.setItem('correlationId', correlationIdResponse.data.correlationId);
                notify.show('Login Success, Enter Otp', 'success', 2500);
                localStorage.setItem('isFirstTimeLogin', true);

                history.push('/verify');
            }
        } catch (error) {
            if (error?.response?.data) {
                notify.show(error?.response?.data?.message, 'error');
            }
        }
        runInAction(() => {
            this.isCorrelationIdLoading = false;
        });
    },

    async getBearerToken(otp) {
        let getTokenresponse;
        try {
            this.isGetTokenLoading = true;
            getTokenresponse = await axios
                .post(
                    `${BASE_URL}/oauth/verify-token`,
                    {
                        correlationId: localStorage.getItem('correlationId'),
                        otpCode: otp,
                    },
                    {
                        auth: {
                            username: BASIC_AUTH_USERNAME,
                            password: BASIC_AUTH_PASSWORD,
                        },
                    }
                )
                .then(async (response) => {
                    localStorage.setItem('expiryDate', response.data.expiry_date);
                    getCubaREST().restApiToken = response.data.access_token;
                    localStorage.setItem('access_token', response.data.access_token);
                    localStorage.setItem('status', response.status);
                    getCubaREST().restApiToken = response.data.access_token;

                    notify.show('You need to change your password', 'success', 2000);
                    localStorage.setItem('isFirstTimeLogin', true);
                    await this.getUserInformation();
                    history.push('/changePassword');
                });
        } catch (error) {
            notify.show(error?.response?.data?.message, 'error');

            runInAction(() => {
                this.isGetTokenLoading = false;
            });
        }
        runInAction(() => {
            this.isGetUserInfoLoading = false;
        });
    },
    async postChangePasswordData(values) {
        const data = {
            changePasswordWrapper: {
                oldPass: values.oldPassword,
                newPassword: values.confirmPassword,
            },
        };

        try {
            this.isPostChangePasswordLoading = true;

            await axios.post(
                `${BASE_URL}/services/miliki_ChangePasswordService/changePassword`,

                data,

                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
                    },
                }
            );
            localStorage.setItem('isFirstTimeLogin', false);
            runInAction(() => {
                this.isPostChangePasswordLoading = false;
            });
            notify.show('You have successfully changed your password', 'success');

            history.push('/dashboard');
        } catch (error) {
            notify.show(error?.response?.data?.message, 'error');
            runInAction(() => {
                this.isPostChangePasswordLoading = false;
            });
        }
    },
    async postForgotPasswordData(data) {
        try {
            this.isPostForgotPasswordLoading = true;
            await axios.post(`${BASE_URL}/forgot/password`, {
                username: `${data}`,
            });
            notify.show('Reset Password Successful,Check your email', 'success');
            history.push('/');
            runInAction(() => {
                this.isPostForgotPasswordLoading = false;
            });
        } catch (error) {
            runInAction(() => {
                this.isPostForgotPasswordLoading = false;
            });
            notify.show(error.response.data.message, 'error');
        }
    },

    async getUserInformation() {
        const getUserInfoResponse = await axios.post(
            `${BASE_URL}/services/miliki_UserParentService/getUserInfo`,
            {
                type: 'trader',
            },
            {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('access_token')}`,
                },
            }
        );
        try {
            localStorage.setItem('user-id', getUserInfoResponse.data.id);
            localStorage.setItem('trader-id', getUserInfoResponse.data.trader.id);
            localStorage.setItem('trader-email', getUserInfoResponse.data.email);
            localStorage.setItem('trader-name', getUserInfoResponse.data.trader.name);
        } catch (error) {
            console.log('Error getting user info:', error);
        }
        return;
    },
});
